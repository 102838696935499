.App {
  text-align: center;
}

.App-header {
  background-color: white;
  background-image: url("./images/bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  min-height: 100svh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(5px + 2vmin);
  color: white;
}

.App-link {
  color: lightgray;
}

.Header-table {
  border: 0;
  width: 98%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.Header-tableTd{
  border: 0;
  padding-top: 100px;
  padding-left: 5px;
  padding-right: 5px;
}

.Header-leftDiv {
  border: darkgrey 5px solid;
  min-height: 400px;
  width: 70%;
  padding-top: 20px;
}

.Header-leftDiv-mobile {
  border: darkgrey 5px solid;
  min-height: 400px;
  width: 95%;
  padding-top: 20px;
}

.Img-KL{
  width: 150px;
  border-radius: 100%;
}

.Profile-name {
  font-size: calc(20px + 3vmin);
  font-weight: bold;
}

.Profile-title{
  text-align: left;
  font-size: calc(18px);
  color: darkorange;
  font-weight: bold;
}

.Profile-header {
  font-size: calc(10px + 3vmin);
  font-weight: bold;
  text-align: left;
}

.Profile-content {
  font-size: calc(10px + 1vmin);
  text-align: left;
  width: 80%;
  color: lightgray;
}

.Profile-contact {
  font-size: calc(15px);
  text-align: left;
  width: 70%;
  font-weight: bold;
}

.Profile-hr {
  border-top: 2px dotted cadetblue;
  border-bottom: 0;
}

.Profile-contact-table {
  border: 0;
  width: 100%;
  padding: 0;
}

.Profile-contact-td {
  border: 0;
  padding-left: 0;
  padding-bottom: 15px;
}

.Profile-contact-td2 {
  border: 0;
  padding-left: 0;
  padding-bottom: 15px;
  color: lightgray;
}

.Menu-top-div {
  position: fixed;
  top: 50px;
  right: 20px;
  background: cornflowerblue;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  line-height: 50px;
  color: white;
  z-index: 3;
}

.Menu-body-div {
  position: fixed;
  top: 75px;
  right: 20px;
  background: cornflowerblue;
  width: 50px;
  height: 190px;
  padding-top: 20px;
  line-height: 35px;
  color: white;
  z-index: 2;
}

.Menu-alt-div {
  position: fixed;
  top: 75px;
  right: 75px;
  width: 100px;
  height: 190px;
  padding-top: 20px;
  line-height: 35px;
  color: white;
  z-index: 3;
}

.Menu-ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.Alt-ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.Alt-li {
  background: #212121;
}

.Menu-bottom-div {
  position: fixed;
  top: 260px;
  right: 20px;
  background: cornflowerblue;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  line-height: 50px;
  color: white;
  z-index: 1;
}

.Menu-link {
  color: white;
  text-underline: none;
}

.Download-link {
  color: gray;
  text-underline: none;
}

.Down-div {
  height: 200px;
  padding-top: 50px;
  font-size: calc(18px);
}

.About-div {
  min-height: 600px;
  width: 100%;
  padding-top: 30px;
  font-size: calc(5px + 2vmin);
}

.About-header {
  font-weight: bold;
  font-size: calc(20px + 3vmin);
  color: yellowgreen;
  height: 200px;
  line-height: 200px;
  align-content: center;
}

.About-table {
  border: 0;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.Img-About {
  max-width: 100%;
  align-self: center;
  border-radius: 2%;
}

.About-img-td {
  border: 0;
  vertical-align: top;
}

.About-text-td {
  text-align: left;
  vertical-align: top;
  padding-left: 10px;
  padding-right: 50px;
  padding-top: 10px;
  border: 0;
  font-size: calc(10px + 1vmin);
  color: gray;
}

.About-text-header {
  font-weight: bold;
  color: darkorange;
  font-size: calc(14px + 1vmin);
}

.About-text-content {
  font-size: calc(10px + 1vmin);
  color: gray;
  font-weight: lighter;
}

.Exp-div {
  min-height: 900px;
  width: 100%;
  padding-top: 30px;
  font-size: calc(5px + 2vmin);
  background: #212121;
}

.Exp-header {
  font-weight: bold;
  font-size: calc(20px + 3vmin);
  color: yellowgreen;
  height: 200px;
  line-height: 200px;
  align-content: center;
}

.Exp-table {
  border: 0;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  vertical-align: top;
}

.Exp-name {
  font-weight: bold;
  color: white;
  font-size: calc(14px + 1vmin);
  vertical-align: top;
}

.Exp-title {
  font-weight: bold;
  color: orange;
  font-size: calc(10px + 1vmin);
  vertical-align: top;
  padding-top: 15px;
}

.Exp-detail {
  color: darkgrey;
  font-size: calc(10px + 1vmin);
  vertical-align: top;
  padding-top: 15px;
  padding-right: 15px;
  padding-left: 0;
}

.Exp-hr {
  border-top: 2px dotted darkorange;
  border-bottom: 0;
  width: 98%;
}

.Skill-div {
  min-height: 900px;
  width: 100%;
  padding-top: 30px;
  font-size: calc(5px + 2vmin);
  background-color: white;
  background-image: url("./images/coding.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}

.Skill-header {
  font-weight: bold;
  font-size: calc(20px + 3vmin);
  color: yellowgreen;
  height: 200px;
  line-height: 200px;
  align-content: center;
}

.Skill-table {
  border: 0;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  vertical-align: top;
}

.Skill-td {
  vertical-align: top;
}

.Skill-text-header {
  font-weight: bold;
  color: darkorange;
  font-size: calc(14px + 1vmin);
}

.Skill-text-content {
  font-size: calc(10px + 1vmin);
  color: white;
  font-weight: lighter;
}

.Project-div {
  min-height: 900px;
  width: 100%;
  padding-top: 30px;
  font-size: calc(5px + 2vmin);
  background-color: white;
  color: #212121;
}

.Project-header {
  font-weight: bold;
  font-size: calc(20px + 3vmin);
  color: yellowgreen;
  height: 200px;
  line-height: 200px;
  align-content: center;
}

.Project-table {
  border: 0;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  vertical-align: top;
}

.Project-td {
  vertical-align: top;
}

.Project-text-header {
  font-weight: bold;
  color: darkorange;
  font-size: calc(14px + 1vmin);
}

.Project-text-content {
  font-size: calc(10px + 1vmin);
  color: gray;
}

.Project-hr {
  border-top: 2px dotted darkgrey;
  border-bottom: 0;
  width: 100%;
}

.CQ-table {
  border: 1px solid black;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  vertical-align: top;
}

.CQ-table1 {
  border: 1px solid black;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  vertical-align: top;
  display: none;
}

.CQ-td {
  vertical-align: top;
  border: 1px solid black;
}

.CQ-title-td {
  vertical-align: top;
  border: 1px solid black;
  background-color: gray;
  color: white;
}

.Input-div {
  height: 15vh;
  width: 100%;
}

.Input_box {
  width: 50%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border-radius: 15px;
  border-width: 0;
  font-size: calc(15px);
}

.Input_button {
  padding: 13px 20px;
  margin: 8px 0;
  border: 2px;
  text-decoration: none;
  border-radius: 15px;
}